import { createContext, useState } from "react";

const tabs = [
  { name: "All" }, 
  { name: "Supplier-Goods" }, 
  { name: "Supplier-Services" }, 
  { name: "Food-Beverage" }, 
  { name: "Retail" }
];

export const GroupPurchaseTabsContext = createContext();
export const GroupPurchaseCurrentTabContext = createContext();

export const GroupPurchaseContextProvider = (props) => {
  const [currentTab, setTab] = useState(tabs[0]);
  const contextValue = { currentTab, setTab };

  return (
    <GroupPurchaseTabsContext.Provider value={{ tabs }}>
      <GroupPurchaseCurrentTabContext.Provider value={contextValue}>
        {props.children}
      </GroupPurchaseCurrentTabContext.Provider>
    </GroupPurchaseTabsContext.Provider>
  );
};
