import { Fragment, useEffect, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import Select from "react-select";
import { useForm, Controller } from "react-hook-form";
import { InfoCircle } from "iconsax-react";
import commonConfigs from "../../../../config/config";
import axios from "axios";
// import { AZURE_BLOB_CONTAINER_PRODUCTS } from "../../../../constants";
// import { useUploadFile } from "../../../../commons/components/uploadFileCard";
// import { getRandomUUID } from "../../../../utils/custom/helper";

const getSupplierUrl = commonConfigs.apiUrls.Suppliers();
const getTypeUrl = commonConfigs.apiUrls.MerchantType();
const createGPOUrl = commonConfigs.apiUrls.CreateGroupPurchase();
const getGPOUrl = commonConfigs.apiUrls.GroupPurchase();

const user = JSON.parse(localStorage.getItem("user"));

const errorIcon = (
  <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
    <InfoCircle className="h-5 w-5 text-red-500" aria-hidden="true" />
  </div>
);

const visibilityOptions = [
  { value: 1, label: "True" },
  { value: 0, label: "False" },
];

export default function OrderingModal({ setOpenModal, load }) {
  const [isDisabled, setIsDisabled] = useState(false);
  const [open, setOpen] = useState(true);
  const [menuTypes, setType] = useState([]);
  const [supplier, setSupplier] = useState([]);

  const [supplierType, setSupplierType] = useState([]);

  const [skus, setSKUs] = useState([]);

  const [optionLoad, setOptionLoad] = useState(true);

  // const [file, fileCard, uploadFile] = useUploadFile({
  //   container: AZURE_BLOB_CONTAINER_PRODUCTS,
  // });

  const {
    register,
    formState: { errors },
    handleSubmit,
    control,
  } = useForm({});

  function formatData(data) {
    var supplier = data.supplier.value;
    var menu_type = supplierType.sys_code_id;
    var is_visible = data.is_visible.value;

    var newData = data;

    newData.supplier = supplier;
    newData.menu_type = menu_type;
    newData.is_visible = is_visible;
    // newData.product_img_url = null;
    newData.updated_by = user.account_id;

    return newData;
  }

  async function getSkus() {
    await axios
      .get(getGPOUrl, { headers: commonConfigs.apiHeader })
      .then((res) => {
        setSKUs(res.data.result.data);
        setOptionLoad(false);
      })
      .catch((err) => {
        console.log(err);
      });
  }
  const checkSKUDuplicates = (value) => {
    for (var i = 0; i < skus.length; i++) {
      if (skus[i].product_sku === value) {
        return true;
      } else {
        return false;
      }
    }
    // skus.map((d) => {
    //   if (value === d.product_sku) {
    //     return true;
    //   } else {
    //     return false;
    //   }
    // });
  };

  const onSubmit = async (data) => {
    setIsDisabled(true); // Disable the submit button
    const formatteddata = formatData(data);
    console.log("formatData(data)", formatteddata);

    // if (file) {
    //   const url = await uploadFile(`${getRandomUUID()}`);

    //   if (url) {
    //     formatteddata.product_img_url = url;
    //   }
    // }
    axios
      .post(createGPOUrl, formatteddata, { headers: commonConfigs.apiHeader })
      .then((res) => {
        try {
          if (res.data.status !== "success") {
            alert("danger");
          } else {
            alert("success");
          }
        } catch (err) {
          console.log(err);
        } finally {
          setIsDisabled(false); // Enable the submit button after API call
          load(true);
          setOpenModal(false);
        }
      })
      .catch((err) => {
        const err_msg = JSON.stringify(err.response.data.result.message);
        alert(`Error code : ${err.response.status} , ${err_msg}`);
        setIsDisabled(false); // Enable the submit button if there's an error
      });
  };

  useEffect(() => {
    async function getTypeOption() {
      await axios
        .get(getTypeUrl, { headers: commonConfigs.apiHeader })
        .then((res) => {
          setType(res.data.result.data);
          setOptionLoad(false);
        })
        .catch((err) => {
          console.log(err);
        });
    }

    async function getSupplierOptions() {
      await axios
        .get(getSupplierUrl, { headers: commonConfigs.apiHeader })
        .then((res) => {
          setSupplier(res.data.result.data.filter(item => item.is_deleted === 0));
          setOptionLoad(false);

          // res.data.result.data.forEach((type) => {
          //   if (type.merchant_id == data.supplier.value) {
          //     setSupplierType(type.merchant_type);
          //   }
          // });


        })
        .catch((err) => {
          console.log(err);
        });
    }


    if (optionLoad) {
      getTypeOption().catch((err) => {
        console.log("err for types: " + err);
      });
      getSupplierOptions().catch((err) => {
        console.log("err for supplier: " + err);
      });
      getSkus().catch((err) => {
        console.log("err for skus: " + err);
      });
    }
  }, []);

  const menuTypesOptions = menuTypes.map((d) => ({
    value: d.sys_code_id,
    label: d.merchant_type,
  }));

  const supplierOptions = supplier.map((d) => ({
    value: d.merchant_id,
    label: d.merchant_name,
  }));

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="fixed z-10 inset-0 overflow-y-auto"
        onClose={setOpen}
      >
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay
              className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
              onClick={() => setOpenModal(false)}
            />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
              <form onSubmit={handleSubmit(onSubmit)}>
                <div>
                  <div className="mt-3 sm:mt-5">
                    <Dialog.Title
                      as="h3"
                      className="text-lg leading-6 font-medium text-gray-900 mb-3"
                    >
                      Create Ordering Menu
                    </Dialog.Title>
                    {/* <div className="mt-2 sm:col-span-6">
                      <label
                        htmlFor="menu_type"
                        className="block text-sm font-medium text-yellow-800"
                      >
                        Menu Type
                      </label>
                      <Controller
                        name="menu_type"
                        control={control}
                        render={({ field }) => (
                          <Select
                            isClearable
                            {...field}
                            options={menuTypesOptions}
                            isSearchable={true}
                            placeholder="Select a Type"
                            className="text-sm"
                          />
                        )}
                      />
                    </div> */}

                    <div className="mt-2 sm:col-span-6">
                      <label
                        htmlFor="supplier"
                        className="block text-sm font-medium text-yellow-800"
                      >
                        Supplier
                      </label>
                      <Controller
                        name="supplier"
                        control={control}
                        render={({ field }) => (
                          <Select
                            isClearable
                            {...field}
                            options={supplierOptions}
                            isSearchable={true}
                            placeholder="Select a Supplier"
                            className="text-sm"
                            onChange={(selectedOption) => {
                              field.onChange(selectedOption); // React Hook Form onChange
                              const selectedSupplier = supplierOptions.find(
                                (option) => option.value === selectedOption?.value
                              );
                              if (selectedSupplier) {
                                const supplierId = selectedSupplier.value; // Assuming supplier ID is stored in 'value' field
                                const selectedSupplierType = supplier.find(
                                  (type) => type.merchant_id === supplierId
                                );
                                if (selectedSupplierType) {
                                  setSupplierType(selectedSupplierType.merchant_type);
                                } else {
                                  setSupplierType(''); // Reset if no matching supplier type
                                }
                              }
                            }}
                          />
                        )}
                      />
                    </div>

                    <div className="mt-2 sm:col-span-6">
                      <label
                        htmlFor="leave_request"
                        className="block text-sm font-medium text-yellow-800"
                      >
                        Supplier Type
                      </label>
                      <div className="mt-1 relative rounded-md shadow-sm">
                        <input
                          type="text"
                          name="leave_request"
                          id="leave_request"
                          style={{ backgroundColor: '#ececec' }}
                          className="block w-full pr-10 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md bg-white"
                          readOnly
                          value={supplierType.sys_code_desc}
                        />
                      </div>
                    </div>

                    <div className="mt-2 sm:col-span-6">
                      <label
                        htmlFor="product_name"
                        className="block text-sm font-medium text-yellow-800"
                      >
                        Item Name
                      </label>
                      <div className="mt-1 relative rounded-md shadow-sm">
                        <input
                          type="text"
                          name="product_name"
                          id="product_name"
                          className={
                            !errors.product_name
                              ? "block w-full pr-10 border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md bg-white"
                              : "block w-full pr-10 border-red-300 text-red-900 placeholder-red-900 focus:outline-none focus:ring-red-500 focus:border-red-500 sm:text-sm rounded-md"
                          }
                          placeholder={"Enter Item Name"}
                          {...register("product_name", { required: true })}
                        />
                        {errors.product_name?.type === "required" && errorIcon}
                      </div>
                    </div>
                    <div className="mt-2 sm:col-span-6">
                      <label
                        htmlFor="product_sku"
                        className="block text-sm font-medium text-yellow-800"
                      >
                        Item SKU
                      </label>
                      <div className="mt-1 relative rounded-md shadow-sm">
                        <input
                          type="text"
                          name="product_sku"
                          id="product_sku"
                          className={
                            !errors.product_sku
                              ? "block w-full pr-10 border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md bg-white"
                              : "block w-full pr-10 border-red-300 text-red-900 placeholder-red-900 focus:outline-none focus:ring-red-500 focus:border-red-500 sm:text-sm rounded-md"
                          }
                          placeholder={"Enter Item SKU"}
                          {...register("product_sku", {
                            required: true
                            // ,
                            // validate: (value) =>
                            //   checkSKUDuplicates(value) ||
                            //   "There is a duplicate",
                          })}                 
                        />

                        {errors.product_sku && (
                          <p>{errors.product_sku.message}</p>
                        )}
                      </div>
                    </div>
                    <div className="mt-2 sm:col-span-6">
                      <label
                        htmlFor="product_upc"
                        className="block text-sm font-medium text-yellow-800"
                      >
                        Item UPC
                      </label>
                      <div className="mt-1 relative rounded-md shadow-sm">
                        <input
                          type="text"
                          name="product_upc"
                          id="product_upc"
                          className={
                            !errors.product_upc
                              ? "block w-full pr-10 border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md bg-white"
                              : "block w-full pr-10 border-red-300 text-red-900 placeholder-red-900 focus:outline-none focus:ring-red-500 focus:border-red-500 sm:text-sm rounded-md"
                          }
                          placeholder={"Enter Item UPC"}
                          {...register("product_upc", { required: true })}
                        />
                        {errors.product_upc?.type === "required" && errorIcon}
                      </div>
                    </div>
                    <div className="mt-2 sm:col-span-6">
                      <label
                        htmlFor="itemDesc"
                        className="block text-sm font-medium text-yellow-800"
                      >
                        Item Description
                      </label>
                      <div className="mt-1 relative rounded-md shadow-sm">
                        <input
                          type="text"
                          name="product_desc"
                          id="product_desc"
                          className={
                            !errors.product_desc
                              ? "block w-full pr-10 border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md bg-white"
                              : "block w-full pr-10 border-red-300 text-red-900 placeholder-red-900 focus:outline-none focus:ring-red-500 focus:border-red-500 sm:text-sm rounded-md"
                          }
                          placeholder={"Enter Item Description"}
                          {...register("product_desc", { required: true })}
                        />
                        {errors.product_desc?.type === "required" && errorIcon}
                      </div>
                    </div>

                    <div className="mt-2 sm:col-span-6">
                      <label
                        htmlFor="is_visible"
                        className="block text-sm font-medium text-yellow-800"
                      >
                        Visibility
                      </label>
                      <Controller
                        name="is_visible"
                        control={control}
                        render={({ field }) => (
                          <Select
                            isClearable
                            {...field}
                            options={visibilityOptions}
                            isSearchable={true}
                            placeholder="Select the Visibility"
                            className="text-sm"
                          />
                        )}
                      />
                    </div>

                    <div className="mt-2 sm:col-span-6">
                      <label
                        htmlFor="product_price"
                        className="block text-sm font-medium text-yellow-800"
                      >
                        Item Price
                      </label>
                      <div className="mt-1 relative rounded-md shadow-sm">
                        <input
                          type="number"
                          min="0"
                          name="product_price"
                          id="product_price"
                          className={
                            !errors.product_price
                              ? "block w-full pr-10 border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md bg-white"
                              : "block w-full pr-10 border-red-300 text-red-900 placeholder-red-900 focus:outline-none focus:ring-red-500 focus:border-red-500 sm:text-sm rounded-md"
                          }
                          placeholder={"Enter Item Price"}
                          {...register("product_price", {
                            required: true,
                            valueAsNumber: true,
                            pattern: /^(?:\d*\.\d{1,2}|\d+)$/,
                            // validate: (number) => parseInt(number) > 0,
                          })}
                        />
                        {errors.product_price?.type === "required" && errorIcon}
                      </div>
                    </div>
                    
                    {/* <div className="sm:col-span-6 mt-2">
                      <label
                        htmlFor="upload_file"
                        className="block text-sm font-medium text-yellow-800"
                      >
                        Upload Image
                      </label>
                      {fileCard}
                    </div> */}
                  </div>
                </div>

                <div className="mt-5 sm:mt-6 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense">
                  <button
                    type="submit"
                    className="w-full inline-flex justify-center border-transparent px-4 py-2 border-none text-base font-medium text-yellow-800 hover:text-yellow-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:col-start-2 sm:text-sm"
                    disabled={isDisabled} // Disable the button when isDisabled
                  >
                    Submit
                  </button>
                  <button
                    type="button"
                    className="mt-3 w-full inline-flex justify-center rounded-md px-4 py-2 border-none bg-white text-base font-medium text-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:col-start-1 sm:text-sm"
                    onClick={() => setOpenModal(false) && load(true)}
                  >
                    Cancel
                  </button>
                </div>
              </form>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
